import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getHome } from "../../features/home/homeSlice";
import { selectHome } from "../../features/home/homeSelectors";
import parse from "html-react-parser";
import Spinner from "../../components/Spinner/Spinner";

import "./Home.css";

function Home() {

  const dispatch = useDispatch();

  const { home, isLoading, isSuccess, isError, message } =
    useSelector(selectHome);

  useEffect(() => {
    if (isError) console.log(message);
    if (!isSuccess) dispatch(getHome());
  }, [isError, isSuccess, message, dispatch]);

  if (isLoading) return <Spinner absolute={true} main={true}/>;

  return (
    <div className="page home">
      {isSuccess && (
        <div className="home-section">
          <h1>{home.title}</h1>
          <>{parse(home.content)}</>
          {home.url && home.button && (
            <Link to={home.url}>
              <button className="button" type="button">
                {home.button}
              </button>
            </Link>
          )}
        </div>
      )}
    </div>
  );
}

export default Home;
