import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import socialsService from "./socialsService";

const initialState = {
  socials: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get socials
export const getSocials = createAsyncThunk('socials/getAll', async(_, thunkAPI) => {
    try {
      const { API_URL } = thunkAPI.getState().settings
      return await socialsService.getSocials(API_URL)
    } catch(error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
  })

export const socialsSlice = createSlice({
  name: "socials",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSocials.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getSocials.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.socials = action.payload
      })
      .addCase(getSocials.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
  }
});

export const { reset } = socialsSlice.actions;
export default socialsSlice.reducer;