import Bio from "../pages/Bio/Bio";
import Contact from "../pages/Contact/Contact";
import Home from "../pages/Home/Home";
import Projects from "../pages/Projects/Projects";
import Project from "../pages/Projects/Project";
import References from "../pages/References/References";

export const routesSK = [
  {
    title: "Domov",
    path: "/",
    page: <Home title="Domov" />,
    show: true
  },
  {
    title: "Životopis",
    path: "/zivotopis",
    page: <Bio title="Životopis" />,
    show: true
  },
  {
    title: "Projekty",
    path: "/projekty",
    page: <Projects title="Projekty" />,
    show: true
  },
  {
    title: "Projekt",
    path: "/projekty/:id",
    page: <Project title="Projekt" />,
    show: false
  },
  {
    title: "Referencie",
    path: "/referencie",
    page: <References title="Referencie" />,
    show: true
  },
  {
    title: "Kontakt",
    path: "/kontakt",
    page: <Contact title="Kontakt" />,
    show: true
  },
];

export const routesEN = [
  {
    title: "Home",
    path: "/en/home",
    page: <Home title="Home" />,
    show: true
  },
  {
    title: "Biography",
    path: "/en/biography",
    page: <Bio title="Biography" />,
    show: true
  },
  {
    title: "Projects",
    path: "/en/projects",
    page: <Projects title="Projects" />,
    show: true
  },
  {
    title: "Project",
    path: "/en/projects/:id",
    page: <Project title="Project" />,
    show: false
  },
  {
    title: "References",
    path: "/en/references",
    page: <References title="References" />,
    show: true
  },
  {
    title: "Contact",
    path: "/en/contact",
    page: <Contact title="Contact" />,
    show: true
  },
];

export const routes = [...routesSK, ...routesEN]
