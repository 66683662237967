import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import profileService from './profileService'

const initialState = {
  profile: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get profile
export const getProfile = createAsyncThunk('profiles/getProfile', async(_, thunkAPI) => {
  try {
    const { API_URL } = thunkAPI.getState().settings
    return await profileService.getProfile(API_URL)
  } catch(error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
      return thunkAPI.rejectWithValue(message)
  }
})

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfile.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.profile = action.payload
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
  }
});

export const { reset } = profileSlice.actions;
export default profileSlice.reducer;
