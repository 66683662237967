import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  language: null,
  API_URL: null
}

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    reset: (state) => initialState,
    setLanguage: (state, action) => {
      state.language = action.payload
      if (action.payload === 'SK') {
        process.env.NODE_ENV === "production"
        ? state.API_URL = "https://peterrosa.admin.ninodesign.sk"
        : state.API_URL = "http://peterrosa.local"
      } else if (action.payload === 'EN') {
        process.env.NODE_ENV === "production"
        ? state.API_URL = "https://peterrosa.admin.ninodesign.sk/en"
        : state.API_URL = "http://peterrosa.local/en"
      }
    }
  }
});

export const { reset, setLanguage } = settingsSlice.actions;
export default settingsSlice.reducer;
