import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectBio } from "../../features/bio/bioSelectors";
import { getBioSections } from "../../features/bio/bioSlice";
import Spinner from "../../components/Spinner/Spinner";
import About from "../../sections/About/About";
import Contact from "../../sections/Contact/Contact";
import Work from "../../sections/Work/Work";
import Education from "../../sections/Education/Education";
import Skills from "../../sections/Skills/Skills";
import Languages from "../../sections/Languages/Languages";
import Hobby from "../../sections/Hobby/Hobby";

import "./Bio.css";

function Bio() {
  
  const dispatch = useDispatch();

  const { bio, isLoading, isError, isSuccess, message } =
    useSelector(selectBio);

  useEffect(() => {
    if (isError) console.log(message);
    if (!isSuccess) dispatch(getBioSections());
  }, [isError, isSuccess, message, dispatch]);

  const downloadData = bio.find((sec) => sec.categories.includes("download"));

  if (isLoading) return <Spinner absolute={true} main={true}/>;

  return (
    <div className="page bio">
      {isSuccess && (
        <>
          <About data={bio.find((sec) => sec.categories.includes("about"))} />
          <Contact data={bio.find((sec) => sec.categories.includes("contact"))} />
          <Work data={bio.find((sec) => sec.categories.includes("work"))} />
          <Education data={bio.find((sec) => sec.categories.includes("education"))} />
          <Skills data={bio.find((sec) => sec.categories.includes("skills"))} />
          <Languages data={bio.find((sec) => sec.categories.includes("languages"))} />
          <Hobby data={bio.find((sec) => sec.categories.includes("hobby"))} />
          {downloadData && (
            <form className="d-flex justify-content-center download-pdf-button" method="get" action={`${downloadData.file.url}`} target="_blank">
              <button target="_blank" className="button" type="submit">{downloadData.button}</button>
            </form>
          )}
        </>
      )}
    </div>
  );
}

export default Bio;
