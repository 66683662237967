import {
  configureStore,
  combineReducers
} from "@reduxjs/toolkit";

import settingsReducer from "../features/settings/settingsSlice";
import profileReducer from "../features/profile/profileSlice";
import homeReducer from "../features/home/homeSlice";
import bioReducer from "../features/bio/bioSlice";
import socialsReducer from "../features/socials/socialsSlice";
import projectsReducer from "../features/projects/projectsSlice";
import referencesReducer from "../features/references/referencesSlice";
import contactReducer from "../features/contact/contactSlice";

const combinedReducer = combineReducers({
  settings: settingsReducer,
  profile: profileReducer,
  home: homeReducer,
  bio: bioReducer,
  socials: socialsReducer,
  projects: projectsReducer,
  references: referencesReducer,
  contact: contactReducer
});

const rootReducer = (state, action) => {
  if (action.type === "settings/setLanguage") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer
});
