import axios from 'axios';
        
const getSocials = async (API_URL) => {
    const responseSocials = await axios.get(`${API_URL}/wp-json/wp/v2/socials`)
    if (responseSocials.data) {
        return responseSocials.data.map(val => {
            return {
                title: val.title.rendered,
                icon: {
                    url: val.acf.icon.url,
                    alt: val.acf.icon.alt
                },
                url: val.acf.link
            }
        })
    }
};

const socialsService = {
    getSocials
};

export default socialsService;
