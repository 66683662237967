import Heading from "../../components/Heading/Heading";

import "./Hobby.css";

function Hobby({ data }) {
  return (
    <>
      {data && (
        <div className="hobby-section">
          <Heading title={data.title} />
          <div className="hobby-content content">
            {data.data?.map((val, index) => (
              <p key={index} className="hobby">{val.title}</p>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default Hobby;
