import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { setLanguage } from "../../features/settings/settingsSlice";
import { NavLink } from "react-router-dom";
import { routesSK, routesEN } from "../../routes/routes";
import { selectSettings } from "../../features/settings/settingsSelectors";
import { selectProfile } from "../../features/profile/profileSelectors";
import { Link } from "react-router-dom";

import "./Header.css";

function Header() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { language } = useSelector(selectSettings);
  const { profile } = useSelector(selectProfile);
  const routes = language === "SK" ? routesSK : routesEN;

  const toggleLanguage = () => {
    dispatch(setLanguage(language === "SK" ? "EN" : "SK"));
    if (language === "EN") {
      const routeIdx = routesEN.findIndex((route) => {
        return route.path === location.pathname;
      });
      routeIdx > -1 && navigate(routesSK[routeIdx].path);
    } else {
      const routeIdx = routesSK.findIndex((route) => {
        return route.path === location.pathname;
      });
      routeIdx > -1 && navigate(routesEN[routeIdx].path);
    }
  };

  return (
    <nav className="header navbar navbar-expand-xl">
      <Link
        className="navbar-brand"
        to={`${language === "SK" ? "/" : "/en/home"}`}
      >
        {profile && <h3 className="profile-title">{profile.title}</h3>}
      </Link>
      <button
        className="navbar-toggler ms-auto p-0 border-0"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="true"
        aria-label="Toggle navigation"
      >
        <div className="navbar-toggler-icon p-0">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav w-100">
          {routes
            .filter((route) => route.show)
            .map((route, index) => (
              <li
                className="nav-item"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                key={index}
              >
                <NavLink
                  className="nav-link"
                  to={route.path}
                  end={
                    route.path === "/" || route.path === "/en/home"
                      ? true
                      : false
                  }
                >
                  {({ isActive }) => (
                    <span className={isActive ? "active" : ""}>
                      {route.title}
                    </span>
                  )}
                </NavLink>
              </li>
            ))}
          <li
            className="nav-item lang-change"
            data-bs-toggle="collapse"
            data-bs-target=".navbar-collapse.show"
          >
            <button
              type="button"
              onClick={toggleLanguage}
              disabled={language === "SK"}
            >
              SK
            </button>
            <span>/</span>
            <button
              type="button"
              onClick={toggleLanguage}
              disabled={language === "EN"}
            >
              EN
            </button>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Header;
