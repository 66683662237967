import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getContact,
  postContact,
  resetContact,
} from "../../features/contact/contactSlice";
import { selectContact } from "../../features/contact/contactSelectors";
import { selectSettings } from "../../features/settings/settingsSelectors";
import Recaptcha from "react-google-invisible-recaptcha";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Heading from "../../components/Heading/Heading";
import Spinner from "../../components/Spinner/Spinner";

import "./Contact.css";

function Contact({ title }) {
  const dispatch = useDispatch();

  const { language } = useSelector(selectSettings);
  const { contact, contactForm, isLoading, isSuccess, isError, message } =
    useSelector(selectContact);

  const formRef = useRef(null);
  const recaptchaRef = useRef(null);

  useEffect(() => {
    if (isError) console.log(message);
    if (!isSuccess) dispatch(getContact());
    if (contact.isSuccess) {
      formRef.current.reset();
      toast.success(contact.message.message);
      dispatch(resetContact());
    }
    if (contact.isError) {
      toast.error(contact.message.message);
      dispatch(resetContact());
    }
  }, [
    isError,
    isSuccess,
    message,
    contact.isError,
    contact.isSuccess,
    contact.message,
    dispatch,
  ]);

  if (isLoading) return <Spinner absolute={true} main={true} />;

  const handleSubmit = (e) => {
    e.preventDefault();
    let formData = {};
    for (let i = 0; i < e.target.length; i++) {
      formData[e.target[i].name] = {
        value: e.target[i].value,
        error: contactForm.data.find((val) => val.name === e.target[i].name)
          ?.error,
      };
    }
    formData.error = contactForm.error;
    formData.success = contactForm.success;
    formData.language = language;
    recaptchaRef.current.callbacks.execute();
    dispatch(postContact(formData));
  };

  return (
    <div className="page contact">
      <Heading title={title} />
      {isSuccess && (
        <div className="contact-content">
          <form onSubmit={handleSubmit} ref={formRef}>
            {contactForm.data.map((val, index) => {
              if (val.type === "text-area") {
                return (
                  <div key={index} className="textarea-field">
                    <textarea
                      placeholder={val.label}
                      name={val.name}
                      required={val.required === "true"}
                      disabled={contact.isLoading}
                      rows="5"
                    />
                  </div>
                );
              } else if (val.type === "submit") {
                return (
                  <div key={index} className="button-field">
                    <button
                      className="button"
                      disabled={contact.isLoading}
                      required={val.required === "true"}
                      type={val.type}
                      name={val.name}
                    >
                      {contact.isLoading && (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                      {val.label}
                    </button>
                  </div>
                );
              } else if (val.type === "checkbox") {
                return (
                  <div key={index} className="checkbox-field">
                    <label>
                      <input
                        type={val.type}
                        name={val.name}
                        required={val.required === "true"}
                        disabled={contact.isLoading}
                      />
                      {val.label}
                      <span className="checkmark"></span>
                    </label>
                  </div>
                );
              } else {
                return (
                  <div key={index} className="input-field">
                    <input
                      type={val.type}
                      name={val.name}
                      required={val.required === "true"}
                      placeholder={val.label}
                      disabled={contact.isLoading}
                    />
                  </div>
                );
              }
            })}
          </form>
          <Recaptcha
            ref={recaptchaRef}
            sitekey="6Le-K3IhAAAAAM5KCYpA5JA0FUF43b_VvDkYEH9s"
          />
          <div className="contact-emails-phones">
            <div className="emails contact-paragraph">
              {contactForm.emails?.icon && (
                <img
                  className="contact-icon icon"
                  src={contactForm.emails.icon?.url}
                  alt={contactForm.emails.icon?.alt}
                />
              )}
              <div className="contact-block">
                {contactForm.emails?.data?.map((val, index) => (
                  <span key={index}>
                    <Link
                      to="#"
                      onClick={(e) => {
                        window.location.href = `mailto:${val.address}`;
                        e.preventDefault();
                      }}
                    >
                      {val.address}
                    </Link>
                    {index + 1 !== contactForm.emails.data.length ? ", " : ""}
                  </span>
                ))}
              </div>
            </div>
            <div className="phones contact-paragraph">
              {contactForm.phones?.icon && (
                <img
                  className="contact-icon icon"
                  src={contactForm.phones.icon?.url}
                  alt={contactForm.phones.icon?.alt}
                />
              )}
              <div className="contact-block">
                {contactForm.phones?.data?.map((val, index) => (
                  <span key={index}>
                    <Link
                      to="#"
                      onClick={(e) => {
                        window.location.href = `tel:${val.prefix}${val.number}`;
                        e.preventDefault();
                      }}
                    >
                      {`${val.prefix} ${val.number.replace(
                        /(?=(?:.{3})*$)/g,
                        " "
                      )}`}
                    </Link>
                    {index + 1 !== contactForm.phones.data.length ? ", " : ""}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Contact;
