import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../features/profile/profileSlice";
import { selectProfile } from "../../features/profile/profileSelectors";
import Spinner from "../../components/Spinner/Spinner";

import "./Profile.css";

function Profile() {
  const dispatch = useDispatch();
  
  const { profile, isLoading, isError, isSuccess, message } =
    useSelector(selectProfile);

  useEffect(() => {
    if (isError) console.log(message);
    if (!isSuccess) dispatch(getProfile());
  }, [isError, isSuccess, message, dispatch]);

  if (isLoading) return <Spinner absolute={true}/>;

  return (
    <div className="profile-section">
      {isSuccess && (
        <>
          {profile.image && (
            <figure className="p-0 m-0">
              <img
                className="w-100"
                src={profile.image.url}
                alt={profile.image.alt}
              />
            </figure>
          )}
          <div className="profile-text text-center">
            <h3 className="profile-title">{profile.title}</h3>
            <p className="profile-subheading">{profile.subheading}</p>
          </div>
        </>
      )}
    </div>
  );
}

export default Profile;
