import Heading from "../../components/Heading/Heading";
import { Link } from "react-router-dom";

import "./Contact.css";

function Contact({ data }) {
  return (
    <>
      {data && (
        <div className="contact-section section">
          <Heading title={data.title} />
          <div className="contact-content content">
            <div className="emails contact-paragraph">
              {data.emails?.icon && (
                <img
                  className="contact-icon icon"
                  src={data.emails.icon?.url}
                  alt={data.emails.icon?.alt}
                />
              )}
              <div className="contact-block">
                {data.emails?.data?.map((val, index) => (
                  <span key={index}>
                    <Link
                      to="#"
                      onClick={(e) => {
                        window.location.href = `mailto:${val.address}`;
                        e.preventDefault();
                      }}
                    >
                      {val.address}
                    </Link>
                    {index + 1 !== data.emails.data.length ? ", " : ""}
                  </span>
                ))}
              </div>
            </div>
            <div className="phones contact-paragraph">
              {data.phones?.icon && (
                <img
                  className="contact-icon icon"
                  src={data.phones.icon?.url}
                  alt={data.phones.icon?.alt}
                />
              )}
              <div className="contact-block">
                {data.phones?.data?.map((val, index) => (
                  <span key={index}>
                    <Link
                      to="#"
                      onClick={(e) => {
                        window.location.href = `tel:${val.prefix}${val.number}`;
                        e.preventDefault();
                      }}
                    >
                      {`${val.prefix} ${val.number.replace(
                        /(?=(?:.{3})*$)/g,
                        " "
                      )}`}
                    </Link>
                    {index + 1 !== data.phones.data.length ? ", " : ""}
                  </span>
                ))}
              </div>
            </div>
            {/* <div className="address contact-paragraph">
              {data.address?.icon && (
                <img
                  className="contact-icon icon"
                  src={data.address.icon?.url}
                  alt={data.address.icon?.alt}
                />
              )}
              <div className="contact-block">
                <span>
                  <Link
                    to="#"
                    onClick={(e) => {
                      window.open(
                        `https://www.google.com/maps?q=${data.address?.title}`,
                        "_blank"
                      );
                      e.preventDefault();
                    }}
                  >
                    {data.address?.title}
                  </Link>
                </span>
              </div>
            </div> */}
            <div className="birthday contact-paragraph">
              {data.birthday?.icon && (
                <img
                  className="contact-icon icon"
                  src={data.birthday.icon?.url}
                  alt={data.birthday.icon?.alt}
                />
              )}
              <div className="contact-block">
                {data.birthday?.date.replaceAll("/", ".")}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Contact;
