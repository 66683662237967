import Heading from "../../components/Heading/Heading";
import ProgressBar from "../../components/ProgressBar/ProgressBar";

import "./Languages.css";

function Languages({ data }) {
  return (
    <>
      {data && (
        <div className="languages-section">
          <Heading title={data.title} />
          <div className="languages-content content">
            {data.data?.map((val, index) => (
              <ProgressBar key={index} data={val} />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default Languages;
