import Heading from "../../components/Heading/Heading";
import ProgressBar from "../../components/ProgressBar/ProgressBar";

import "./Skills.css";

function Skills({ data }) {
  return (
    <>
      {data && (
        <div className="skills-section">
          <Heading title={data.title} />
          <div className="skills-content content">
          {data.data?.map((val, index) => (
            <ProgressBar key={index} data={val}/>
          ))}
          </div>
        </div>
      )}
    </>
  );
}

export default Skills;
