import axios from 'axios';

const getProfile = async (API_URL) => {
    const responseProfile = await axios.get(`${API_URL}/wp-json/wp/v2/profiles?per_page=1`)
    if (responseProfile.data) {
        const profile = responseProfile.data[0]
        return {
            title: profile.title?.rendered,
            subheading: profile.acf?.subheading,
            image: {
                url: profile.acf?.image?.url,
                alt: profile.acf?.image?.alt,
            }
        }
    }
};

const profileService = {
    getProfile
};

export default profileService;
