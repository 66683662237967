import Heading from "../../components/Heading/Heading"

import "./Education.css"

function Education({data}) {
  return (
    <>
      {data && (
        <div className="education-section">
          <Heading title={data?.title} />
          <div className="education-content content">
            {data.data?.map((val, index) => (
              <div key={index} className="education-item">
                <div className="education-term">
                  <p className="education-from">{val.from}</p>
                  <p className="education-to">{val.to}</p>
                </div>
                <div className="education-info">
                  <p className="education-school">{val.school}</p>
                  <p className="education-field">{val.field}</p>
                  <p className="education-place">{val.place}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default Education