import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import projectsService from "./projectsService";

const initialState = {
  projects: {
    data: [],
    pages: null,
    page: 1,
    perPage: 8,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    activeProject: {
      data: {},
      isError: false,
      isSuccess: false,
      isLoading: false,
      message: "",
    }
  },
  categories: {
    data: [],
    activeCategory: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

// Get projects
export const getProjects = createAsyncThunk('projects/getAll', async(_, thunkAPI) => {
  try {
    const { API_URL } = thunkAPI.getState().settings;
    const { page, perPage } = thunkAPI.getState().projects.projects;
    const { activeCategory } = thunkAPI.getState().projects.categories;
    return await projectsService.getProjects(API_URL, page, perPage, activeCategory, thunkAPI.dispatch)
  } catch(error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
      return thunkAPI.rejectWithValue(message)
  }
})

// Get project by slug
export const getProjectBySlug = createAsyncThunk('projects/getProjectBySlug', async(slug, thunkAPI) => {
  try {
    const { API_URL } = thunkAPI.getState().settings;
    return await projectsService.getProjectBySlug(API_URL, slug, thunkAPI.dispatch)
  } catch(error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
      return thunkAPI.rejectWithValue(message)
  }
})

// Get categories
export const getCategories = createAsyncThunk('projects/getCategories', async(_, thunkAPI) => {
  try {
    const { API_URL } = thunkAPI.getState().settings;
    const { activeCategory } = thunkAPI.getState().projects.categories;
    return await projectsService.getCategories(API_URL, activeCategory, thunkAPI.dispatch)
  } catch(error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
      return thunkAPI.rejectWithValue(message)
  }
})

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    reset: (state) => initialState,
    resetProjects: (state) => {
      state.projects = initialState.projects
    },
    setPages: (state, action) => {
      state.projects.pages = action.payload
    },
    setActiveProject: (state, action) => {
      state.projects.activeProject.data = action.payload
      state.projects.activeProject.isLoading = false
      state.projects.activeProject.isSuccess = true
    },
    setActiveCategory: (state, action) => {
      state.categories.activeCategory = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProjects.pending, (state) => {
        state.projects.isLoading = true
      })
      .addCase(getProjects.fulfilled, (state, action) => {
        state.projects.isLoading = false
        state.projects.isSuccess = true
        state.projects.data = [...state.projects.data, ...action.payload]
        state.projects.page += 1
      })
      .addCase(getProjects.rejected, (state, action) => {
        state.projects.isLoading = false
        state.projects.isError = true
        state.projects.message = action.payload
      })
      .addCase(getCategories.pending, (state) => {
        state.categories.isLoading = true
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.categories.isLoading = false
        state.categories.isSuccess = true
        state.categories.data = action.payload
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.categories.isLoading = false
        state.categories.isError = true
        state.categories.message = action.payload
      })
      .addCase(getProjectBySlug.pending, (state) => {
        state.projects.activeProject.isLoading = true
      })
      .addCase(getProjectBySlug.fulfilled, (state, action) => {
        state.projects.activeProject.isLoading = false
        state.projects.activeProject.isSuccess = true
        state.projects.activeProject.data = action.payload
      })
      .addCase(getProjectBySlug.rejected, (state, action) => {
        state.projects.activeProject.isLoading = false
        state.projects.activeProject.isError = true
        state.projects.activeProject.message = action.payload
      })
  }
});

export const { reset, resetProjects, setPages, setActiveProject, setActiveCategory } = projectsSlice.actions;
export default projectsSlice.reducer;