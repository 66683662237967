import axios from 'axios';
import { setPages, setActiveCategory } from "./projectsSlice";
        
const getProjects = async (API_URL, page, perPage, category, dispatch) => {
  const responseProjects = await axios.get(
    `${API_URL}/wp-json/wp/v2/projects?per_page=${perPage}&page=${page}&categories=${category.id}&_embed`
  );
  if (responseProjects.headers) {
    dispatch(setPages(parseInt(responseProjects.headers["x-wp-totalpages"])));
  }
  if (responseProjects.data) {
    return responseProjects.data.map((project) => ({
      title: project.title.rendered,
      slug: project.slug,
      categories: project._embedded["wp:term"][0].map((category) => ({
        id: category.id,
        name: category.name,
        slug: category.slug,
      })),
      data:
        project.acf.data &&
        project.acf.data.map((val) => ({
          description: val.description,
          image: {
            largeUrl: val.image.sizes.medium_large,
            url: val.image.url,
            alt: val.image.alt,
          },
        })),
    }));
  }
};

const getProjectBySlug = async (API_URL, slug) => {
  const responseProject = await axios.get(
    `${API_URL}/wp-json/wp/v2/projects?slug=${slug}&_embed`
  );
  if (responseProject.data) {
    const project = responseProject.data[0];
    return {
      title: project.title.rendered,
      slug: project.slug,
      categories: project._embedded["wp:term"][0].map((category) => ({
        id: category.id,
        name: category.name,
        slug: category.slug,
      })),
      data:
        project.acf.data &&
        project.acf.data.map((val) => ({
          description: val.description,
          image: {
            largeUrl: val.image.sizes.medium_large,
            url: val.image.url,
            alt: val.image.alt,
          },
        })),
    };
  }
};

const getCategories = async (API_URL, category, dispatch) => {
  const responseProjectCategory = await axios.get(
    `${API_URL}/wp-json/wp/v2/categories?slug=projects`
  );
  if (responseProjectCategory.data) {
    const projectCategory = responseProjectCategory.data[0];
    const responseProjectCategories = await axios.get(
      `${API_URL}/wp-json/wp/v2/categories?parent=${projectCategory.id}`
    );
    if (responseProjectCategories.data) {
      let categories = responseProjectCategories.data.map((category) => ({
        id: category.id,
        name: category.name,
        slug: category.slug,
      }));
      categories.unshift({
        id: projectCategory.id,
        name: projectCategory.name,
        slug: projectCategory.slug,
      });
      !category && dispatch(
        setActiveCategory(
          categories.find((category) => category.slug === "projects")
        )
      );
      return categories;
    }
  }
};

const projectsService = {
    getProjects,
    getProjectBySlug,
    getCategories
};

export default projectsService;
