import Heading from "../../components/Heading/Heading"

import "./Work.css"

function Work({data}) {
  return (
    <>
      {data && (
        <div className="work-section">
          <Heading title={data?.title} />
          <div className="work-content content">
            {data.data?.map((val, index) => (
              <div key={index} className="work-item">
                <div className="work-term">
                  <p className="work-from">{val.from}</p>
                  <p className="work-to">{val.to}</p>
                </div>
                <div className="work-info">
                  <p className="work-position">{val.position}</p>
                  <p className="work-company">{val.company}</p>
                  <p className="work-additional">{val.additional}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default Work