import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import referencesService from "./referencesService";

const initialState = {
  references: [],
  pages: null,
  page: 1,
  perPage: 6,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get references
export const getReferences = createAsyncThunk('references/getAll', async(_, thunkAPI) => {
    try {
      const { API_URL } = thunkAPI.getState().settings;
      const { page, perPage } = thunkAPI.getState().references;
      return await referencesService.getReferences({API_URL, page, perPage}, thunkAPI.dispatch)
    } catch(error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
  })

export const referencesSlice = createSlice({
  name: "references",
  initialState,
  reducers: {
    reset: (state) => initialState,
    setPages: (state, action) => {
      state.pages = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReferences.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getReferences.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.references = [...state.references, ...action.payload];
        state.page += 1;
      })
      .addCase(getReferences.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset, setPages } = referencesSlice.actions;
export default referencesSlice.reducer;