import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation} from "react-router-dom";
import { setLanguage } from "../features/settings/settingsSlice";
import { store } from "../app/store";

function RouteChange() {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    const routeParts = location.pathname.split("/");
    if (routeParts[1] === 'en' && store.getState().settings.language !== 'EN') {
      dispatch(setLanguage('EN'))
    }
    if (routeParts[1] !== 'en' && store.getState().settings.language !== 'SK') {
      dispatch(setLanguage('SK'))
    }
  }, [dispatch, location.pathname]);
}

export default RouteChange
