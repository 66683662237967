import { useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { selectSettings } from "./features/settings/settingsSelectors";
import { routes } from "./routes/routes";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Profile from "./sections/Profile/Profile";
import PageNotFound from "./pages/PageNotFound";
import RouteChange from "./routes/RouteChange";
import "./App.css"

function App() {
  const { language } = useSelector(selectSettings);

  return (
    <>
      <Router>
        <RouteChange />
        <div className="container-fluid p-0 m-0 d-flex">
          <div className="left-side">
            <Profile />
          </div>
          <div className="right-side">
            <Header />
            <div className="main">
              <Routes>
                {routes.map((route, index) => (
                  <Route key={index} path={route.path} element={route.page} />
                ))}
                <Route
                  path="*"
                  element={
                    <PageNotFound
                      title={
                        language === "SK"
                          ? "Stránka nenájdená"
                          : "Page not found"
                      }
                    />
                  }
                />
              </Routes>
            </div>
            <Footer />
          </div>
        </div>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
