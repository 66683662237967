import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import contactService from "./contactService";

const initialState = {
  contact: {
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  contactForm: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get contact
export const getContact = createAsyncThunk('contact/get', async(_, thunkAPI) => {
  try {
    const { API_URL } = thunkAPI.getState().settings
    return await contactService.getContact(API_URL)
  } catch(error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
      return thunkAPI.rejectWithValue(message)
  }
})

// Post contact
export const postContact = createAsyncThunk('contact/post', async(data, thunkAPI) => {
  try {
    const { API_URL } = thunkAPI.getState().settings
    return await contactService.postContact(API_URL, data)
  } catch(error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
      return thunkAPI.rejectWithValue(message)
  }
})

export const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    reset: (state) => initialState,
    resetContact: (state) => {
      return {...state, contact: initialState.contact}
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContact.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getContact.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.contactForm = action.payload
      })
      .addCase(getContact.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(postContact.pending, (state) => {
        state.contact.isLoading = true
      })
      .addCase(postContact.fulfilled, (state, action) => {
        state.contact.isLoading = false
        state.contact.isSuccess = true
        state.contact.message = action.payload
      })
      .addCase(postContact.rejected, (state, action) => {
        state.contact.isLoading = false
        state.contact.isError = true
        state.contact.message = action.payload
      })
  }
});

export const { reset, resetContact} = contactSlice.actions;
export default contactSlice.reducer;