import React from "react";
import "./Spinner.css";

function Spinner({ absolute, main, initial}) {
  return (
    <div className={`lds-ring ${absolute ? 'absolute-ring' : ''} ${main ? 'main-ring' : ''} ${initial ? 'initial-ring': ''}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default Spinner;
