import "./ProgressBar.css";

function ProgressBar({data}) {
  return (
    <div className="pb">
      <div className="pb-content">
        <p className="pb-title">{data.title}</p>
        <p className="pb-subtitle">{data.subtitle}</p>
      </div>
      <div className="progress">
        <div
          className="progress-bar progress-bar-striped progress-bar-animated"
          style={{ width: `${data.percentage}%` }}
        ></div>
      </div>
      <p className="pb-percentage">{data.percentage + "%"}</p>
    </div>
  );
}

export default ProgressBar;
