import axios from "axios";
import { setPages } from "../../features/references/referencesSlice";

const getReferences = async (data, dispatch) => {
  const { API_URL, page, perPage } = data;
  const responseReferences = await axios.get(
    `${API_URL}/wp-json/wp/v2/references?per_page=${perPage}&page=${page}`
  );
  if (responseReferences.headers) {
    dispatch(setPages(parseInt(responseReferences.headers['x-wp-totalpages'])))
  }
  if (responseReferences.data) {
    return responseReferences.data.map((val) => {
      return {
        name: val.acf.name,
        surname: val.acf.surname,
        company: val.acf.company,
        web: val.acf.web,
        image: val.acf.image && {
          url: val.acf.image.url,
          alt: val.acf.image.alt,
        },
        content: val.acf.content,
      };
    });
  }
};

const referencesService = {
  getReferences,
};

export default referencesService;
