import axios from 'axios';
        
const getBioSections = async (API_URL) => {
    const responseBio = await axios.get(`${API_URL}/wp-json/wp/v2/biography?_embed=wp:term`)
    if (responseBio.data) {
        return responseBio.data.map(val => {
            return {
                title: val.title.rendered,
                categories: val._embedded['wp:term'][0].map(category => category.slug),
                ...val.acf
            }
        })
    }
};

const bioService = {
    getBioSections
};

export default bioService;
