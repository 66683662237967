import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectReferences } from "../../features/references/referencesSelectors";
import { getReferences } from "../../features/references/referencesSlice";
import { ReactComponent as DoubleQuotes } from "../../assets/double-quotes.svg";
import { ReactComponent as DoubleArrowsBottom } from "../../assets/double-arrows-bottom.svg";
import Spinner from "../../components/Spinner/Spinner";
import Heading from "../../components/Heading/Heading";

import "./References.css";

function References({title}) {
  
  const dispatch = useDispatch();
  const [scrollPosition, setScrollPosition] = useState(null);

  const { references, pages, page, isError, isSuccess, isLoading, message } =
    useSelector(selectReferences);

  useEffect(() => {
    if (isError) console.log(message)
    if (!isSuccess) dispatch(getReferences())
    if (scrollPosition && references) window.scrollTo(0, scrollPosition);
  }, [isError, isSuccess, message, scrollPosition, references, dispatch]);

  if (isLoading) return <Spinner absolute={true} main={true}/>;
  
  return (
    <div className="page references">
      <Heading title={title} />
      {isLoading && <Spinner additionalClass="spinner-main" />}
      {isSuccess && (
        <div className="references-content">
          {references.map((reference, index) => (
            <div key={index} className="reference">
              <div className="reference-image">
                {reference.image ? (
                  <img src={reference.image.url} alt={reference.image.alt} />
                ) : (
                  <DoubleQuotes />
                )}
              </div>
              <p className="reference-content">{reference.content}</p>
              <p className="reference-name-surname-company">
                {reference.name} {reference.surname}{" "}
                {reference.company && "| " + reference.company}
              </p>
              {reference.web && (
                <small className="reference-web">
                  {reference.web.map((val, index) => (
                    <span key={index}>
                      <a
                        key={index}
                        rel="noreferrer"
                        target="_blank"
                        href={val.url}
                      >
                        {val.name}
                      </a>
                      {index + 1 !== reference.web.length ? ", " : ""}
                    </span>
                  ))}
                </small>
              )}
            </div>
          ))}
        </div>
      )}
      {page <= pages && (
        <div
          className="arrows-bottom"
          onClick={() => {
            setScrollPosition(window.pageYOffset);
            dispatch(getReferences());
          }}
        >
          <DoubleArrowsBottom />
        </div>
      )}
    </div>
  );
}

export default References;
