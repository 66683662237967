import { useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, Link } from "react-router-dom";
import parse from "html-react-parser";
import { selectActiveProject } from "../../features/projects/projectsSelectors";
import { selectSettings } from "../../features/settings/settingsSelectors";
import { setActiveCategory, resetProjects, getProjectBySlug} from "../../features/projects/projectsSlice";
import Spinner from "../../components/Spinner/Spinner";
import Heading from "../../components/Heading/Heading";

import "./Project.css";

function Project() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  const activeProject = useSelector(selectActiveProject);
  const { language } = useSelector(selectSettings);

  useEffect(() => {
    language === "SK" ? navigate(`/projekty/${id}`) : navigate(`/en/projects/${id}`) ;
    if (activeProject.isError) console.log(activeProject.message);
    if (!activeProject.isSuccess) dispatch(getProjectBySlug(id));
  }, [
    activeProject.isSuccess,
    activeProject.isError,
    activeProject.message,
    id,
    language,
    dispatch,
    navigate
  ]);

  if (activeProject.isLoading) return <Spinner absolute={true} main={true}/>;

  return (
    <div className="page project">
      {activeProject.isSuccess && (
        <>
          <Heading title={activeProject.data.title} />
          <p className="project-category">
            {activeProject.data.categories
              .filter((category) => category.slug !== "projects")
              .map((category, index) => (
                <Link
                  key={index}
                  to={`${language === "SK" ? "/projekty" : "/en/projects"}`}
                  onClick={() => {
                    dispatch(setActiveCategory(category));
                    dispatch(resetProjects());
                  }}
                >
                  {parse(category.name)}
                </Link>
              ))}
          </p>
          {activeProject.data.data.map((val, index) => (
            <div className="project-content" key={index}>
              <p className="project-description">{val.description}</p>
              <figure key={index} className="p-0 m-0">
                <img
                  className="w-100 h-100"
                  src={val.image.url}
                  alt={val.image.alt}
                />
              </figure>
            </div>
          ))}
        </>
      )}
      {activeProject.isError && (
        <h1>
          {language === "SK"
            ? "Projekt neexistuje"
            : "The project does not exist"}
        </h1>
      )}
    </div>
  );
}

export default Project;
