import Heading from "../../components/Heading/Heading";

import "./About.css";

function About({ data }) {
  return (
    <>
      {data && (
        <div className="about-section section">
          <Heading title={data.title} />
          <div className="about-content content">
            {data.content?.map((val, index) => (
              <p key={index}>
                {val.section}
              </p>
            ))}
            {data.image && (
              <figure className="p-0 m-0">
                <img
                  className="w-100"
                  src={data.image.url}
                  alt={data.image.alt}
                />
              </figure>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default About;
