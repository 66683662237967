import axios from 'axios';

const getContact = async (API_URL) => {
    const responseContact = await axios.get(`${API_URL}/wp-json/wp/v2/forms?per_page=1`)
    if (responseContact.data) {
        return responseContact.data[0].acf
    }
};

const postContact = async (API_URL, data) => {
    // Musim posielat na live page.
    const responseContact = await axios.post(`${API_URL}/wp-json/contact/v1/send`, data);
    if (responseContact.data) {
        return responseContact.data
    }
}
const contactService = {
    getContact,
    postContact
};

export default contactService
