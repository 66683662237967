import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSocials } from "../../features/socials/socialsSlice";
import { selectSocials } from "../../features/socials/socialsSelectors";

import "./Footer.css";

function Footer() {

  const dispatch = useDispatch();

  const { socials, isError, isSuccess, isLoading, message } =
    useSelector(selectSocials);

  useEffect(() => {
    if (isError) {console.log(message)}
    if (!isSuccess) dispatch(getSocials());
  }, [isError, isSuccess, message, dispatch]);

  return (
    <footer className="footer">
      {isLoading && <h1 className="footer-loader">...</h1>}
      {isSuccess && (
        <>
          {socials.map((val, index) => (
            <a
              key={index}
              href={val.url}
              target="_blank"
              rel="noopener noreferrer"
              tabIndex="0"
            >
              <img
                className="footer-icon icon"
                src={val.icon?.url}
                alt={val.icon?.alt}
              />
            </a>
          ))}
        </>
      )}
    </footer>
  );
}

export default Footer;
