import axios from 'axios';

const getHome = async (API_URL) => {
    const responseHome = await axios.get(`${API_URL}/wp-json/wp/v2/homes?per_page=1`)
    if (responseHome.data) {
        const home = responseHome.data[0]
        return {
            title: home.title?.rendered,
            content: home.content?.rendered,
            button: home.acf?.button,
            url: home.acf?.url,
        }
    }
};

const homeService = {
    getHome,
};

export default homeService;
