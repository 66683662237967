import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import bioService from "./bioService";

const initialState = {
  bio: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get bio sections
export const getBioSections = createAsyncThunk('bioSections/getAll', async(_, thunkAPI) => {
    try {
      const { API_URL } = thunkAPI.getState().settings;
      return await bioService.getBioSections(API_URL)
    } catch(error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
  })

export const bioSlice = createSlice({
  name: "bio",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBioSections.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getBioSections.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.bio = action.payload
      })
      .addCase(getBioSections.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
  }
});

export const { reset } = bioSlice.actions;
export default bioSlice.reducer;