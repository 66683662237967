import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { selectProjects, selectCategories } from "../../features/projects/projectsSelectors";
import { selectSettings } from "../../features/settings/settingsSelectors";
import { getCategories, getProjects, resetProjects, setActiveCategory, setActiveProject } from "../../features/projects/projectsSlice";
import Spinner from "../../components/Spinner/Spinner";
import Heading from "../../components/Heading/Heading";
import { ReactComponent as DoubleArrowsBottom } from "../../assets/double-arrows-bottom.svg";

import "./Projects.css";

function Projects({title}) {

  const dispatch = useDispatch();

  const { language } = useSelector(selectSettings);
  const categories = useSelector(selectCategories);
  const projects = useSelector(selectProjects);

  useEffect(() => {
    if (categories.isError) console.log(categories.message);
    if (!categories.isSuccess) dispatch(getCategories());

    if (categories.isSuccess) {
      if (projects.isError) console.log(projects.message);
      if (!projects.isSuccess) dispatch(getProjects());
    }
  }, [
    categories.isError,
    categories.isSuccess,
    categories.message,
    projects.isError,
    projects.isSuccess,
    projects.message,
    dispatch,
  ]);

  return (
    <div className="page projects">
      <Heading title={title} />
      {categories.isSuccess && (
        <div className="projects-categories">
          {categories.data.map((category, index) => (
            <button
              key={index}
              className={`button-small ${
                category.slug === categories.activeCategory.slug && "active"
              }`}
              type="button"
              onClick={() => {
                dispatch(setActiveCategory(category));
                dispatch(resetProjects());
              }}
            >
              {category.slug === "projects"
                ? language === "SK"
                  ? "Všetko"
                  : "All"
                : parse(category.name)}
            </button>
          ))}
        </div>
      )}
      {projects.isSuccess && (
        <>
          <div className="projects-gallery">
            {projects.data.map((project, index) => (
              <div
                className="project-item"
                key={index}
                onClick={() => dispatch(setActiveProject(project))}
              >
                <Link
                  to={`${language === "SK" ? "/projekty" : "/en/projects"}/${
                    project.slug
                  }`}
                >
                  <figure className="p-0 m-0">
                    <div className="project-item-overlay w-100 h-100">
                      <h3 className="project-item-overlay-caption">
                        {project.title}
                      </h3>
                      <small className="project-item-overlay-category">
                        {project.categories
                          .filter((category) => category.slug !== "projects")
                          .map((category) => parse(category.name))}
                      </small>
                    </div>
                    {project.data && (
                      <img
                        className="w-100 h-100"
                        src={project.data[0].image.largeUrl}
                        alt={project.data[0].image.alt}
                      />
                    )}
                  </figure>
                </Link>
              </div>
            ))}
          </div>
        </>
      )}
      {(categories.isLoading || projects.isLoading) && (
        <Spinner initial={true}/>
      )}
      {projects.page <= projects.pages && (
        <div className="arrows-bottom" onClick={() => dispatch(getProjects())}>
          <DoubleArrowsBottom />
        </div>
      )}
    </div>
  );
}

export default Projects;
